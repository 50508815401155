// Here you can add other styles

.EccoMI__FormButton {
    float: right !important;
    width: 25%;
    margin-top: 10px;
    cursor: pointer;
}

// .EccoMI__Chart {
//     min-height: 10rem !important;
// }

.EccoMI__Table {
    height: 25rem !important;
    overflow-y: scroll;
}